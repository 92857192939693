<template>
    <div class="c-modal-container">
        <div class="c-modal--inner">
            <button class="c-button c-button--close" @click="setMarketingCookies(false)"></button>
<<<<<<< HEAD
            <iframe src="https://taccd1583.emailsys1a.net/215/2854/ca6bcfee26/subscribe/form.html?_g=1734522033" frameborder="0" width="100%" height="100%"></iframe>
=======
            <iframe :src="props.iframeLink" frameborder="0" width="100%" height="100%"></iframe>
>>>>>>> 9bcae3c2af8e993ac84d42304bfaa05cb843135b
        </div>
    </div>
</template>

<script setup>
const { $bus } = useNuxtApp();
const generalStore = useGeneralStore();

const props = defineProps({
    iframeLink: String
});

const setMarketingCookies = (value) => {
    console.log("CHECK STATE", value)
    $bus.$emit("closeMarketingModal", { state: value });
}
</script>

<style>
.c-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    color: var(--color-white);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, 0.7);
}

.c-modal-container .c-button {
    z-index: 100;
    position: absolute;
    right: 15px;
    top: 15px;
}

.c-modal--inner {
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 960px;
    max-height: 768px;
    background-color: var(--color-white);
    position: relative;
}
</style>